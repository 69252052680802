import React, { useContext, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { PetitionContext } from 'services/petition.service';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import ReactGA from 'react-ga';
import Colors from '../../globals/Colors';
import {
  Wrapper,
  ImageWrapper,
  ImageFromS3,
  ShareWrapper,
  Title,
  Desc,
  ButtonWrapper,
  ShareButton,
  CopyTarget,
} from './Styles';

const PetitionCreationSuccess = (props: RouteComponentProps) => {
  const shareUrl: string = window.location.href.replace(
    '/share_for_starters',
    '/'
  );
  const { formatMessage: fm } = useIntl();
  // const shareUrl: string =
  //   'https://community.sumofus.org/petition/1f85df99-bae5-4d26-b2c6-d3c27396424d/';

  const petitionfromContext: any = useContext(PetitionContext);
  const { petitionDetails } = petitionfromContext;
  const { title } = petitionDetails;
  let localeDir: string = localStorage.getItem('dir') || 'ltr';

  const messages = defineMessages({
    greeting: {
      id: 'label.shareDesc',
      defaultMessage:
        'Getting to <bold>5 signatures</bold> makes your petition visible to the whole SumOfUs.org community',
    },
  });
  const shareDesc = fm(messages.greeting, {
    bold: (str) => <b>{str}</b>,
  });

  const emailContent: string = fm(
    {
      id: 'label.emailContent2',
      defaultMessage: `Dear friends, \n\nI just created the petition ${title} and wanted to ask if you could add your name too.\n\nThis campaign means a lot to me and the more support we can get behind it, the better chance we have of succeeding. You can read more and sign the petition here:\n\n${shareUrl}?source=mlt\n\nThank you!\n\nP.S. Can you also take a moment to share the petition with others? It's really easy – all you need to do is forward this email or share this link on Facebook or Twitter: ${shareUrl}?source=mlt`,
    },
    {
      title: title,
      url: shareUrl,
    }
  );

  const [copyOptions, setCopyOptions] = useState<{
    text: string;
    icon: string;
  }>({
    text: fm({
      id: 'label.shareCopyClipboard',
      defaultMessage: 'Tap to copy to clipboard',
    }),
    icon: 'fa-link',
  });

  const shareOptions = [
    {
      name: 'Facebook',
      content: fm({
        id: 'label.shareInFb',
        defaultMessage: 'Share on Facebook',
      }),
      link: `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
      backGroundColor: Colors.facebookBlue,
      borderColor: Colors.facebookBlue,
      textColor: Colors.white,
      icon: 'fa-facebook',
      iconColor: Colors.white,
    },

    {
      name: 'Email',
      content: fm({
        id: 'label.shareInEmail',
        defaultMessage: 'Send email to your friends',
      }),
      link: `https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&su=Sign+the+petition+${title}&body=${encodeURI(
        emailContent
      )}`,
      backGroundColor: Colors.redOrange,
      borderColor: Colors.redOrange,
      textColor: Colors.white,
      icon: 'fa-envelope-o',
      iconColor: Colors.white,
    },
    {
      name: 'Twitter',
      content: fm({
        id: 'label.shareInTwitter',
        defaultMessage: 'Tweet to your followers',
      }),
      link: `https://twitter.com/share?url=${shareUrl}`,
      backGroundColor: Colors.twitterBlue,
      borderColor: Colors.twitterBlue,
      textColor: Colors.white,
      icon: 'fa-twitter',
      iconColor: Colors.white,
    },
    {
      name: 'Whatsapp',
      content: fm({
        id: 'label.shareInWhatsapp',
        defaultMessage: 'Send via Whatsapp',
      }),
      link: `https://web.whatsapp.com/send?text=${shareUrl}`,
      backGroundColor: Colors.transparent,
      borderColor: Colors.darkGray,
      textColor: Colors.black,
      icon: 'fa-whatsapp',
      iconColor: Colors.whatsapp,
    },
    {
      name: 'clipboard',
      content: copyOptions.text,
      link: undefined,
      backGroundColor: Colors.transparent,
      borderColor: Colors.darkGray,
      textColor: Colors.black,
      icon: copyOptions.icon,
      iconColor: Colors.darkGray,
    },
  ];

  function getImageKey(url: string) {
    const imgUrl = url.split('?');
    const imageKey = imgUrl[0].split('/').pop();
    return imageKey;
  }

  const handleClick = (type: string) => {
    switch (type) {
      case 'clipboard':
        const copyElem: any = document.getElementById('copyLinkTarget');
        if (copyElem) {
          copyElem.select();
          copyElem?.setSelectionRange(0, 99999);
          document.execCommand('copy');
          ReactGA.event({
            category: 'Petition',
            action: `Share Click`,
            label: 'Copied link',
          });
          ReactGA.pageview(`/share_for_starters/copied`);
        }
        setCopyOptions({
          text: fm({
            id: 'label.shareCopiedClipboard',
            defaultMessage: 'Copied',
          }),
          icon: 'fa-check-circle',
        });
        return;
      default:
        ReactGA.event({
          category: 'Petition',
          action: `Share Click`,
          label: type,
        });
        ReactGA.pageview(`/share_for_starters/${type}`);
        console.log('social media shared');
        return;
    }
  };

  return (
    <>
      <Wrapper>
        <ImageWrapper>
          <ImageFromS3 imgKey={getImageKey(petitionDetails.imageUrl)} />
          <ShareWrapper>
            <Title>
              <FormattedMessage
                id="label.shareTitle"
                defaultMessage="Now let’s get your first 5 signatures"
              />
            </Title>
            <Desc>{shareDesc}</Desc>
            <ButtonWrapper>
              {shareOptions.map((button) => {
                return (
                  <ShareButton
                    backGroundColor={button.backGroundColor}
                    borderColor={button.borderColor}
                    textColor={button.textColor}
                    iconColor={button.iconColor}
                    onClick={() => handleClick(button.name)}
                    href={button.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={Math.random()}
                    dir={localeDir}
                  >
                    <i className={`fa ${button.icon}`} aria-hidden="true" />
                    {button.content}
                    {button.name === 'clipboard' && (
                      <CopyTarget
                        type="text"
                        value={`${shareUrl}?source=copy`}
                        id="copyLinkTarget"
                        onChange={() => console.log('')}
                      />
                    )}
                  </ShareButton>
                );
              })}
            </ButtonWrapper>
          </ShareWrapper>
        </ImageWrapper>
      </Wrapper>
    </>
  );
};

export default PetitionCreationSuccess;
