export const Step1InstructionsList = [
  {
    titleId: 'label.step1.instHeading1',
    title: 'Keep it short and to the point',
    descId: 'label.step1.instDesc1',
    desc: 'Example: "Make Covid19 vaccines available for all"',
  },
  {
    titleId: 'label.step1.instHeading2',
    title: 'Focus on the solution',
    descId: 'label.step1.instDesc2',
    desc: 'Example: "Pay your employees the minimum £15 an hour"',
  },
  {
    titleId: 'label.step1.instHeading3',
    title: 'Communicate urgency',
    descId: 'label.step1.instDesc3',
    desc:
      'Example: "Approve life-saving medication for my daughter, before it’s too late"',
  },
];

export const Step2InstructionsList = [
  {
    titleId: 'label.step2.instHeading1',
    title: 'Many petitions have companies or businesses as targets',
    descId: 'label.step2.instDesc1',
    desc:
      "Examples: “Amazon” or “Ben's Chicken Shop”. Feel free to also list the CEO or another company official.",
  },
  {
    titleId: 'label.step2.instHeading2',
    title:
      'Politicians, legislatures, or government agencies are also important for creating change in corporate behaviour',
    descId: 'label.step2.instDesc2',
    desc: 'Examples: Your local MP or the city council',
  },
  {
    titleId: 'label.step2.instHeading3',
    title: 'Don’t overthink it',
    descId: 'label.step2.instDesc3',
    desc:
      'You can add or change targets later. For now, just make your best guess and write the rest of your petition!',
  },
];

export const Step3InstructionsList = [
  {
    titleId: 'label.step3.instHeading1',
    title: 'Describe the people involved and the problem they are facing',
    descId: 'label.step3.instDesc1',
    desc:
      'Readers are most likely to take action when they understand who is affected.',
  },
  {
    titleId: 'label.step3.instHeading2',
    title: 'Describe the solution',
    descId: 'label.step3.instDesc2',
    desc:
      'Explain what needs to happen and who can make the change. Make it clear what happens if you win or lose.',
  },
  {
    titleId: 'label.step3.instHeading3',
    title: 'Make it personal',
    descId: 'label.step3.instDesc3',
    desc:
      'Readers are more likely to sign and support your petition if it’s clear why you care.',
  },
  {
    titleId: 'label.step3.instHeading4',
    title: 'Respect others',
    descId: 'label.step3.instDesc4',
    desc: "Don't bully, use hate speech, threaten violence or make things up.",
  },
];

export const Step4InstructionsList = [
  {
    titleId: 'label.step4.instHeading1',
    title: 'Choose a photo that captures the emotion of your petition',
    descId: 'label.step4.instDesc1',
    desc: 'Photos of people or animals work well.',
  },
  {
    titleId: 'label.step4.instHeading2',
    title: 'Try to upload photos that are 1600 x 900 pixels or larger',
    descId: 'label.step4.instDesc2',
    desc: 'Large photos look good on all screen sizes.',
  },
  {
    titleId: 'label.step4.instHeading3',
    title: 'Keep it friendly for all audiences',
    descId: 'label.step4.instDesc3',
    desc:
      "Make sure your photo doesn't include graphic violence or sexual content.",
  },
];

export const Step3ContentLengthDetailsList = [
  {
    backgroundColor: 'rgba(215, 215, 215, 0.5)',
    borderColor: 'rgb(215, 215, 215, 1)',
  },
  {
    backgroundColor: 'rgba(4, 204, 222, 0.1)',
    borderColor: 'rgba(4, 204, 222, 0.3)',
  },
  {
    backgroundColor: 'rgba(72, 194, 88, 0.1)',
    borderColor: 'rgba(72, 194, 88, 0.3)',
  },
];

export const PetitionStatus = [
  {
    key: 'UNMODERATED',
    value: 'Unmoderated',
  },
  {
    key: 'PENDING',
    value: 'Pending',
  },
  {
    key: 'APPROVED',
    value: 'Approved',
  },
  {
    key: 'SPAM',
    value: 'Spam',
  },
  {
    key: 'INAPPROPRIATE',
    value: 'Inappropriate',
  },
  {
    key: 'CLOSED',
    value: 'Closed',
  },
  {
    key: 'REPORTED',
    value: 'Reported',
  },
];

const restrictedStatuses = ['UNMODERATED', 'REPORTED'];
export const ActionablePetitionStatuses = PetitionStatus.filter(status => {
  return !restrictedStatuses.includes(status.key);
});

export const ReportCategories = [
  {
    intlId: 'label.reportReasonDislike',
    key: 'DISLIKE_DISAGREE',
    value: 'I dislike or disagree with this petition',
  },
  {
    intlId: 'label.reportReasonMisleading',
    key: 'MISLEADING_SPAM',
    value: 'Misleading or spam',
  },
  {
    intlId: 'label.reportReasonInfringement',
    key: 'RIGHTS_INFRINGEMENT',
    value: 'Infringes on my rights',
  },
  {
    intlId: 'label.reportReasonHateful',
    key: 'ABUSIVE_HATEFUL',
    value: 'Abusive or hateful',
  },
  {
    intlId: 'label.reportReasonInappropriate',
    key: 'INAPPROPRIATE_IMAGES',
    value: 'Inappropriate images',
  },
  {
    intlId: 'label.reportReasonHarmful',
    key: 'HARMFUL_TO_CHILDREN',
    value: 'Harmful to children',
  },
  {
    intlId: 'label.reportReasonViolence',
    key: 'VIOLENCE_SELF_HARM_SUICIDE',
    value: 'Violence, suicide, or self harm',
  },
  {
    intlId: 'label.reportReasonImpersonation',
    key: 'IMPERSONATION',
    value: 'Impersonation',
  },
];

export const SOUBaseUrl = 'https://www.sumofus.org';
