import React, { useEffect, useContext } from 'react';
import { RouteComponentProps, navigate, Redirect } from '@reach/router';
import styled from 'styled-components';
import Colors from '../../globals/Colors';
import { PetitionStatus } from '../Constants';
import { AuthContext } from '../../services/auth.service';

const Wrapper = styled.div`
  margin: 30px 20px;
  margin-top: 120px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading = styled.h2`
  text-align: center;
`;

const CardWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 80vh;
  .new {
    position: relative;
    overflow: hidden;
    &:after {
      content: '✭';
      padding-left: 32px;
      padding-top: 50px;
      color: ${Colors.goldenYellow};
      position: absolute;
      white-space: pre;
      margin: -20px;
      width: 80px;
      height: 80px;
      top: -20px;
      right: -20px;
      transform: rotate(45deg);
      background-color: ${Colors.redOrange};
    }
  }
`;

const Card = styled.div`
  height: 200px;
  width: 300px;
  margin: 20px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${Colors.seaFogOpacited3};
  color: ${Colors.black};
  cursor: pointer;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.5s ease-in-out;
  transition: background 0.5s ease-in-out;
  &:hover {
    color: ${Colors.white};
    font-weight: 600;
    background: ${Colors.robinsEggBlue};
    box-shadow: 12px 12px 24px 6px rgba(0, 0, 0, 0.15);
  }
`;

const Dashboard = (props: RouteComponentProps) => {
  const authFromContext: any = useContext(AuthContext);
  let isAdmin = false;
  if (sessionStorage.getItem('isAdmin')) {
    isAdmin = sessionStorage.getItem('isAdmin') === 'true';
  } else if (authFromContext) {
    if (
      authFromContext.signInUserSession.accessToken.payload['cognito:groups']
    ) {
      isAdmin =
        authFromContext.signInUserSession.accessToken.payload[
          'cognito:groups'
        ].includes('Admin');
      sessionStorage.setItem('isAdmin', isAdmin.toString());
    }
  }

  useEffect(() => {
    localStorage.removeItem('openedListType');
  }, []);

  return (
    <>
      {isAdmin ? (
        <Wrapper>
          <Heading>Dashboard</Heading>
          <CardWrapper>
            {PetitionStatus.map((status, idx) => {
              return (
                <Card
                  // eslint-disable-next-line react/no-array-index-key
                  key={Math.random()}
                  className={status.key === 'UNMODERATED' ? 'new' : ''}
                  onClick={() => {
                    localStorage.setItem('openedListType', status.value);
                    navigate(`/dashboard/${status.value}/`);
                  }}
                >
                  <div>{`${status.value}`}</div>
                </Card>
              );
            })}
          </CardWrapper>
        </Wrapper>
      ) : (
        <Redirect to="/" noThrow />
      )}
    </>
  );
};

export default Dashboard;
