import { S3Image } from 'aws-amplify-react';
import styled, { keyframes } from 'styled-components';
import Colors from '../../globals/Colors';
import { mobile, tablet } from '../../globals/Devices';
import { Button } from '../Button';

export const ImagePickerContainer = styled.div`
  height: 450px;
  width: 800px;
  background-color: ${Colors.zircon3};
  margin: 20px 0;
  border: dashed 1px ${Colors.eastBay3};
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  @media ${tablet} {
    height: 365px;
    width: 650px;
  }
  @media ${mobile} {
    height: 200px;
    width: 356px;
  }
`;

export const ImagePicker = styled.input`
  height: 200px;
  width: calc(100vw);
  max-width: 800px;
  opacity: 0;
  position: absolute;
  /* top: 55%; */
  &:focus {
    outline-color: transparent;
    outline-width: 0;
  }
`;

export const UploadPhotoButton = styled.button`
  height: 40px;
  border: 0.8px solid ${Colors.lightGrey};
  border-radius: 12px;
`;

const loading = keyframes`
  0% { background: ${Colors.lightGrey} }
  25% { background: ${Colors.darkGray} }
  50% { background: ${Colors.lightGrey} }
  75% { background: ${Colors.darkGray} }
  100% { background: ${Colors.lightGrey} }
`;
export const Wrapper = styled.div`
  border: 0.3px solid ${Colors.lightGrey};
  height: 450px;
  width: 800px;
  display: flex;
  justify-content: center;
  border-radius: 12px;
  margin-bottom: 38px;
  background-color: ${Colors.lightGrey};
  animation-name: ${loading};
  animation-duration: 3s;
  animation-iteration-count: 50;
  @media ${tablet} {
    height: 365px;
    width: 650px;
  }
  @media ${mobile} {
    height: 200px;
    width: 356px;
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 20px 0;
  @media ${mobile} {
    flex-direction: column;
    align-items: center;
  }
`;

export const UploadButton = styled(Button)`
  margin: 0 8px;
  @media ${mobile} {
    max-width: 80%;
    margin: 10px 0;
  }
`;

export const ChangePhotoButton = styled(Button)`
  margin: 0 8px;
  background-color: transparent;
  color: ${Colors.redOrange};
  @media ${mobile} {
    max-width: 80%;
    margin: 10px 0;
  }
`;

export const ImageFromS3 = styled(S3Image)`
  width: 800px;
  height: 450px;
  border-radius: 6px;
  @media ${tablet} {
    height: 365px;
    width: 650px;
  }
  @media ${mobile} {
    height: 200px;
    width: 356px;
  }
`;

export const UploadIcon = styled.img`
  height: 100px;
  width: 100px;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
`;
