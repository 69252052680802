import { S3Image } from 'aws-amplify-react';
import styled from 'styled-components';
import Colors from '../../globals/Colors';
import { mobile } from 'globals/Devices';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  width: 100%;
  margin-bottom: 60px;
  margin-top: 0;
  background-color: ${Colors.whiteSmoke};
  @media ${mobile} {
    padding: 0;
  }
`;

export const ImageFromS3 = styled(S3Image)`
  border-radius: 6px;
  display: none;
`;

export const ShareWrapper = styled.div`
  width: 60vh;
  background-color: ${Colors.white};
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${mobile} {
    padding: 0;
  }
`;

export const Title = styled.div`
  font-size: 36px;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  padding: 20px 0;
  @media ${mobile} {
    font-size: 30px;
  }
`;

export const Desc = styled.div`
  text-align: center;
  /* padding: 0 50px; */
  .bold {
    font-weight: bold;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
`;

interface ShareButtonProps {
  backGroundColor: string;
  borderColor: string;
  textColor: string;
  iconColor: string;
  dir: string
}

export const ShareButton = styled.a<ShareButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 2px solid;
  font-size: 16px;
  cursor: pointer;
  user-select: none;
  outline: none;
  margin: 10px 0;
  font-weight: 600;
  text-decoration: none;
  padding: 10px;
  background-color: ${({ backGroundColor }) => backGroundColor};
  border-color: ${({ borderColor }) => borderColor};
  color: ${({ textColor }) => textColor};
  &:hover {
    box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.1);
  }
  .fa {
    margin-left: ${ props => props.dir === 'rtl' ? '12px' : 'unset' };
    margin-right: ${ props => props.dir === 'ltr' ? '12px' : 'unset' };
    font-size: 24px;
    color: ${({ iconColor }) => iconColor};
  }
`;

export const CopyTarget = styled.input`
  position: absolute;
  top: -999px;
  left: -999px;
`;
