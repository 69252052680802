/* eslint-disable import/prefer-default-export */

import de from '../../locales/de.json';
import fr from '../../locales/fr.json';
import en from '../../locales/en.json';
import es from '../../locales/es.json';
import pt from '../../locales/pt.json';
import ar from '../../locales/ar.json';

export const I18n = { de, fr, en, es, pt, ar };
