/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import styled from 'styled-components';
import { FormikErrors } from 'formik';
import { FormattedMessage } from 'react-intl';
import Colors from '../../globals/Colors';
import OptOutWarning from './OptOutWarning';

export interface ConsentProps extends React.HTMLProps<HTMLInputElement> {
  error?:
    | string
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | undefined;
}

const ErrorContainer = styled.div`
  text-align: left;
  color: red;
  font-size: 14px;
  background-color: ${Colors.red1};
  padding: 2px 5px;
  border: 1px solid ${Colors.red};
  border-radius: 4px;
  border-bottom-width: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
`;

const RadioOption = styled.input`
  margin: 0 10px;
  width: 20px;
  height: 20px;
  opacity: 0;
  z-index: 2;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;
  &:focus {
    outline: none;
  }

  &:checked {
    &::before {
      opacity: 1;
      transition: opacity 1s ease;
    }
  }
`;

interface RadioOptionWrapperProps {
  selected: boolean;
}
const RadioOptionWrapper = styled.div<RadioOptionWrapperProps>`
  margin: 5px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: relative;
  label {
    font-size: 12px;
  }
  &::before {
    content: '';
    border-radius: 100%;
    border: 1px solid ${props => (props.selected ? '#00c0cf' : '#d7d7d7')};
    background: ${props => (props.selected ? '#00c0cf' : '#d7d7d7')};
    width: 20px;
    height: 20px;
    position: absolute;
    top: 5px;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 0;
    transition: background 0.2s;
  }
`;

type RadioWrapperProps = {
  error?:
    | string
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | undefined;
};
const RadioWrapper = styled.div<RadioWrapperProps>`
  border: 1px solid ${({ error }) => (error ? `red` : `transparent`)};
  padding: 10px 6px;
  border-radius: 4px;
  /* background-color: #f9f9f9; */
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

const Consent = (props: ConsentProps) => {
  const { onChange, name, value, error } = props;
  return (
    <>
      {error && <ErrorContainer>Please select an option</ErrorContainer>}
      <RadioWrapper error={error}>
        <RadioOptionWrapper selected={value === 'true'}>
          <label>
            <RadioOption
              type="radio"
              id="yes"
              name={name}
              value={1}
              checked={value === 'true'}
              aria-checked={value === 'true'}
              onChange={onChange}
            />
            <span style={{ paddingLeft: '25px' }}>
              <FormattedMessage
                id="label.consentYes"
                defaultMessage="Yes! Tell me if this campaign wins, and how I can help other relevant campaigns."
              />
            </span>
          </label>
        </RadioOptionWrapper>
        <RadioOptionWrapper selected={value === 'false'}>
          <label>
            <RadioOption
              type="radio"
              id="no"
              name={name}
              value={0}
              checked={value === 'false'}
              aria-checked={value === 'false'}
              onChange={onChange}
            />
            <span style={{ paddingLeft: '25px' }}>
              <FormattedMessage
                id="label.consentNo"
                defaultMessage="No. I do not want to hear about this campaign’s progress or other relevant campaigns."
              />
            </span>
          </label>
        </RadioOptionWrapper>
      </RadioWrapper>
      { value === 'false' ? <OptOutWarning /> : null} 
    </>
  );
};

export default Consent;
