// @ts-nocheck
import React, { useContext, useState, useEffect } from 'react';
import { RouteComponentProps, Redirect } from '@reach/router';
import { Wrapper, Heading, Loading } from './Styles';
import { AuthContext } from '../../services/auth.service';
import { tableData, tableColumns } from './data';
import { useTable, useSortBy } from 'react-table';
import { ReactComponent as ArrowUp } from '../../images/icons/arrowAsc.svg';
import { ReactComponent as ArrowDown } from '../../images/icons/arrowDsc.svg';
import { API, graphqlOperation } from 'aws-amplify';
import { listPetitions } from 'graphql/queries';

const ShareStats = (props: RouteComponentProps) => {
  const authFromContext: any = useContext(AuthContext);
  let isAdmin = false;
  if (sessionStorage.getItem('isAdmin')) {
    isAdmin = sessionStorage.getItem('isAdmin') === 'true';
  } else if (authFromContext) {
    if (
      authFromContext.signInUserSession.accessToken.payload['cognito:groups']
    ) {
      isAdmin =
        authFromContext.signInUserSession.accessToken.payload[
          'cognito:groups'
        ].includes('Admin');
      sessionStorage.setItem('isAdmin', isAdmin.toString());
    }
  }

  const [petitionsList, setPetitionsList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [nextToken, setNextToken] = useState(null);
  const today = new Date();
  today.setDate(today.getDate() - 30);

  const fetchPetitions = async (params) => {
    const resp = await API.graphql(graphqlOperation(listPetitions, params));
    return resp.data.listPetitions;
  };

  useEffect(() => {
    const params = {
      filter: { shareUpdatedAt: { ge: today.toISOString() } },
    };
    fetchPetitions(params).then((response: any) => {
      setPetitionsList([...petitionsList, ...response.items]);
      setNextToken(response.nextToken);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (nextToken && petitionsList.length < 500) {
      const params = {
        filter: { shareUpdatedAt: { ge: today.toISOString() } },
        nextToken,
      };
      fetchPetitions(params).then((response: any) => {
        setPetitionsList([...petitionsList, ...response.items]);
        setNextToken(response.nextToken);
        setLoading(false);
      });
    }
  }, [nextToken]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: tableColumns,
        data: petitionsList,
        initialState: {
          sortBy: [
            {
              id: 'Signs per Share',
              desc: true,
            },
          ],
        },
      },
      useSortBy
    );

  return (
    <>
      {isAdmin ? (
        <Wrapper>
          <Heading>Share stats</Heading>
          {isLoading ? (
            <Loading>Loading...</Loading>
          ) : (
            <>
              <table {...getTableProps()} style={{ border: 'solid 1px blue' }}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          style={{
                            padding: '10px',
                            border: 'solid 1px gray',
                            fontSize: '16px',
                          }}
                        >
                          {column.render('Header')}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <ArrowDown />
                              ) : (
                                <ArrowUp />
                              )
                            ) : (
                              ''
                            )}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row: any) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell: any) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              style={{
                                padding: '10px',
                                border: 'solid 1px gray',
                                fontSize: '16px',
                              }}
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
        </Wrapper>
      ) : (
        <Redirect to="/" noThrow />
      )}
    </>
  );
};

export default ShareStats;
