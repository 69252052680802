import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { navigate, RouteComponentProps } from '@reach/router';
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactGA from 'react-ga';
import Fade from 'react-reveal/Fade';
import Colors from '../../globals/Colors';
import { mobile } from '../../globals/Devices';
import LinkIcon from '../../images/link-icon.png';
import { PetitionContext } from 'services/petition.service';
import Button from 'components/Button';

const SharePetition = (props: RouteComponentProps) => {
  const shareUrl: string = window.location.href.replace('/share', '/');
  const signedUser = localStorage.getItem('signedUser');
  const userDetails = signedUser ? JSON.parse(signedUser) : {};
  const { country, name } = userDetails;
  const [showShareMsg, setShowShareMsg] = useState<boolean>(true);
  const [showShareButtons, setShowShareButtons] = useState<boolean>(false);
  const [showGDPRBanner, setShowGDPRBanner] = useState<boolean>(false);
  const [showClipBoardBanner, setShowClipBoardBanner] =
    useState<boolean>(false);
  const petitionfromContext: any = useContext(PetitionContext);
  const { petitionDetails } = petitionfromContext;
  const { title } = petitionDetails;
  const { formatMessage: fm } = useIntl();

  useEffect(() => {
    function checkDoubleOptIn() {
      if (userDetails.doubleOptIn !== 'true') {
        if (country && (country === 'DE' || country === 'AT')) {
          setShowGDPRBanner(true);
        }
      }
    }
    checkDoubleOptIn();
  }, []);

  function copyToClipBoard(elem: any) {
    const copyText = elem;
    if (copyText) {
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand('copy');
      document.getElementById('copyLinkButton')?.focus();
      ReactGA.event({
        category: 'Petition',
        action: `Share Click`,
        label: 'Copied link',
      });
      ReactGA.pageview('/share_for_actioners/copied');
      setShowClipBoardBanner(true);
      setTimeout(() => {
        setShowClipBoardBanner(false);
      }, 15 * 1000);
    }
  }

  function recordGAevent(type: string) {
    ReactGA.event({
      category: 'Petition',
      action: `Share Click`,
      label: type,
    });
    ReactGA.pageview(`/share_for_actioners/${type}`);
  }

  return (
    <>
      <Wrapper>
        {showGDPRBanner && (
          <Banner>
            <div>
              <FormattedMessage
                id="label.checkEmailValidate"
                defaultMessage="Please check your e-mail to confirm your e-mail address and validate your signature"
              />
            </div>
            <button
              type="submit"
              className="link"
              onClick={() => {
                setShowGDPRBanner(false);
              }}
            >
              <FormattedMessage id="label.ok" defaultMessage="OK" />
            </button>
          </Banner>
        )}
        {showClipBoardBanner && (
          <Banner>
            <div>
              <FormattedMessage
                id="label.copiedClipBoard"
                defaultMessage="Link copied to clipboard. Paste it in your favorite app and spread your support for this petition."
              />
            </div>
          </Banner>
        )}

        {showShareMsg && (
          <Fade bottom>
            <SecMsg style={{ marginBottom: '30px' }}>
              {fm(
                {
                  id: 'label.thanksForSigning',
                  defaultMessage: `Thanks for adding your name to "${title}"!`,
                },
                { title }
              )}
            </SecMsg>
            <MainMsg>
              <FormattedMessage
                id="label.tripleImpact"
                defaultMessage="Now, can you share the campaign with your friends to triple your impact!"
              />
            </MainMsg>
            <Buttons>
              <OptionButton
                onClick={() => {
                  ReactGA.pageview('/share/consent/accept');
                  setShowShareButtons(true);
                  setShowShareMsg(false);
                }}
              >
                <FormattedMessage
                  id="label.tripleImpactAccept"
                  defaultMessage="Yes"
                />
              </OptionButton>
              <OptionButton
                onClick={() => {
                  ReactGA.pageview('/share/consent/decline');
                  setShowShareMsg(false);
                }}
              >
                <FormattedMessage
                  id="label.tripleImpactDecline"
                  defaultMessage="No"
                />
              </OptionButton>
            </Buttons>
          </Fade>
        )}

        {showShareButtons && (
          <>
            <Fade bottom>
              <MainMsg>
                <FormattedMessage
                  id="label.greatChoice"
                  defaultMessage="Great choice! Sharing leads to way more signatures."
                />
              </MainMsg>
              <SecMsg>
                <FormattedMessage
                  id="label.shareWithFriends"
                  defaultMessage="Now go ahead and share it with your friends and family."
                />
              </SecMsg>
            </Fade>
            <SocialShareButtons>
              <Fade bottom delay={400}>
                <FacebookShareButton
                  url={`${shareUrl}?source=fb`}
                  onShareWindowClose={() => recordGAevent('Facebook')}
                >
                  <FacebookIcon size={60} round />
                </FacebookShareButton>
                <TwitterShareButton
                  url={`${shareUrl}?source=tw`}
                  onShareWindowClose={() => recordGAevent('Twitter')}
                >
                  <TwitterIcon size={60} round />
                </TwitterShareButton>
                <EmailShareButton
                  url={''}
                  onShareWindowClose={() => recordGAevent('Email')}
                  subject={fm(
                    {
                      id: 'label.signThePetition',
                      defaultMessage: 'Sign the Petition',
                    },
                    { title: title }
                  )}
                  body={fm(
                    {
                      id: 'label.emailContent1',
                      defaultMessage: `Dear friends, \n\nI just signed the petition ${title} and wanted to ask if you could add your name too.\n\nThis campaign means a lot to me and the more support we can get behind it, the better chance we have of succeeding. You can read more and sign the petition here:\n\n${shareUrl}?source=mlt\n\nThank you!\n\nP.S. Can you also take a moment to share the petition with others? It's really easy – all you need to do is forward this email or share this link on Facebook or Twitter: ${shareUrl}?source=mlt`,
                    },
                    {
                      title: title,
                      url: shareUrl,
                    }
                  )}
                  separator={' '}
                >
                  <EmailIcon size={60} round />
                </EmailShareButton>
                <WhatsappShareButton
                  url={`${shareUrl}?source=whatsapp`}
                  onShareWindowClose={() => recordGAevent('Whatsapp')}
                >
                  <WhatsappIcon size={60} round />
                </WhatsappShareButton>
                <CopyLinkButton
                  id="copyLinkButton"
                  onClick={() => {
                    copyToClipBoard(document.getElementById('copyLinkTarget'));
                  }}
                >
                  <CopyTarget
                    type="text"
                    value={`${shareUrl}?source=copy`}
                    id="copyLinkTarget"
                    onChange={() => console.log('')}
                  />
                  <LinkShareIcon src={LinkIcon} />
                </CopyLinkButton>
              </Fade>
            </SocialShareButtons>
          </>
        )}

        {!showShareButtons && !showShareMsg && (
          <Fade bottom>
            <MainMsg>
              {fm(
                {
                  id: 'label.thanksForTakingAction',
                  defaultMessage: `No problem. Thanks for your support ${name}!`,
                },
                { name }
              )}
            </MainMsg>
            <Button
              onClick={() => {
                navigate('/');
              }}
            >
              {fm({
                id: 'label.goBackHome',
                defaultMessage: 'Go Back Home',
              })}
            </Button>
          </Fade>
        )}
      </Wrapper>
    </>
  );
};

export default SharePetition;

const Wrapper = styled.div`
  display: flex;
  height: 80vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
`;

const MainMsg = styled.div`
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0.22px;
  line-height: 32px;
  color: ${Colors.regalBlue};
  text-align: center;
`;

const SecMsg = styled.div`
  font-size: 20px;
  letter-spacing: 0.15px;
  color: ${Colors.blueWhale7};
  margin: 35px 0 50px;
  text-align: center;
`;

const SocialShareButtons = styled.div`
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  @media ${mobile} {
    flex-flow: wrap;
    width: 100%;
    justify-content: space-between;
  }
`;

const CopyLinkButton = styled.button`
  background: ${Colors.lightGrey};
  width: 60px;
  height: 60px;
  margin: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  outline: none;
  &:focus {
    background: ${Colors.darkGray};
    transition: background 0.2s linear;
  }
`;
const CopyTarget = styled.input`
  position: absolute;
  top: -999px;
  left: -999px;
`;
const LinkShareIcon = styled.img`
  height: 25px;
  width: 25px;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
`;

const Banner = styled.div`
  position: fixed;
  width: 100vw;
  min-height: 60px;
  background: #172340;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${Colors.white};
  padding: 10px 30px;
  .link {
    /* font-weight: bold; */
    font-size: 20px;
    color: ${Colors.white};
    cursor: pointer;
    padding: 2px 20px;
    background: ${Colors.redOrange};
    border: 0.5px solid ${Colors.redOrange};
    border-radius: 6px;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const OptionButton = styled(Button)`
  margin: 40px 20px;
  padding: 20px;
`;
