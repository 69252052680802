import React, {
  FunctionComponent,
  useEffect,
  useState,
  useContext,
} from 'react';
import styled from 'styled-components';
import stripHtml from 'string-strip-html';
import { FormattedMessage, useIntl } from 'react-intl';
import { navigate } from '@reach/router';
import Editor from '../Editor';
import { Button } from '../Button';
import Instructions from '../Instructions';
import {
  Step3InstructionsList,
  Step3ContentLengthDetailsList,
} from '../Constants';
import { AuthContext } from '../../services/auth.service';
import { mobile } from 'globals/Devices';

type Step3Props = {
  formik: any;
  currentStep: number;
  handleNextStep: any;
};

interface CountWrapperProps {
  borderColor: string;
  backgroundColor: string;
}

const CountWrapper = styled.div<CountWrapperProps>`
  margin: 25px 0;
  padding: 16px;
  font-size: 14px;
  border-radius: 4px;
  border: ${({ borderColor }) => `solid 1px ${borderColor}`};
  background-color: ${({ backgroundColor }) => `${backgroundColor}`};
  transition: background-color 0.4s ease-in-out;
  height: 86px;
  display: flex;
  align-items: center;
  @media ${mobile} {
    min-height: 125px;
  }
`;

const ContinueButton = styled(Button)`
  float: right;
  margin: 0;
`;

const LoginBtn = styled(ContinueButton)``;

const Step3: FunctionComponent<Step3Props> = ({
  formik,
  currentStep,
  handleNextStep,
}) => {
  const { formatMessage: fm } = useIntl();

  const [editorContent, setEditorContent] = useState({
    length: 0,
    content: fm({
      id: 'label.contentLength1',
      defaultMessage:
        'Describe what happened, why you are concerned, and what you want to happen now. The most successful petitions tend to be at least 3 paragraphs long — 1000 characters in length',
    }),
    ...Step3ContentLengthDetailsList[0],
  });
  const storageData = localStorage.getItem('newPetition');
  const storageDetail = storageData ? JSON.parse(storageData) : {};

  const authContext = useContext(AuthContext);

  useEffect(() => {
    if (
      formik &&
      formik.values &&
      formik.values.body &&
      formik.values.body.length
    ) {
      const strippedContentLength = stripHtml(formik.values.body).length;
      if (strippedContentLength === 0) {
        setEditorContent({
          length: 0,
          content: fm({
            id: 'label.contentLength1',
            defaultMessage:
              'Describe what happened, why you are concerned, and what you want to happen now. The most successful petitions tend to be at least 3 paragraphs long — 1000 characters in length',
          }),
          ...Step3ContentLengthDetailsList[0],
        });
      } else if (strippedContentLength < 1000) {
        setEditorContent({
          length: strippedContentLength,
          content: fm(
            {
              id: 'label.contentLength2',
              defaultMessage: `Great — you’ve started writing your petition. We recommend adding another ${
                1000 - strippedContentLength
              } more characters before you finish — keep going!`,
            },
            { remaining: `${1000 - strippedContentLength}` }
          ),

          ...Step3ContentLengthDetailsList[1],
        });
      } else if (strippedContentLength > 1000) {
        setEditorContent({
          length: strippedContentLength,
          content: fm(
            {
              id: 'label.contentLength3',
              defaultMessage: `Great — your petition is currently ${strippedContentLength} characters long — that’s within the description length of some of the most successful petitions!`,
            },
            { count: strippedContentLength }
          ),
          ...Step3ContentLengthDetailsList[2],
        });
      }
    }
  }, [formik]);

  return currentStep !== 2 ? null : (
    <>
      <h2>
        <FormattedMessage
          id="label.contentHeading"
          defaultMessage="Explain the problem you want to solve"
        />
      </h2>
      <p>
        <FormattedMessage
          id="label.contentDesc"
          defaultMessage="People are more likely to support your petition if it’s clear why you care. Explain how this change will impact you, your family, or your community."
        />
      </p>
      <div
        style={{
          height: '600px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Editor
          value={formik.values.body}
          onChange={(value: any) => {
            formik.setFieldValue('body', value);
          }}
          error={formik.errors.body ? fm({ id: formik.errors.title }) : ''}
        />
        <CountWrapper
          borderColor={editorContent.borderColor}
          backgroundColor={editorContent.backgroundColor}
        >
          {editorContent.content}
        </CountWrapper>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
        {authContext ? (
          <ContinueButton
            type="button"
            onClick={handleNextStep}
            disabled={formik.errors.body || editorContent.length === 0}
          >
            <FormattedMessage id="label.continue" defaultMessage="Continue" />
          </ContinueButton>
        ) : (
          <LoginBtn
            type="button"
            onClick={() => {
              localStorage.setItem(
                'newPetition',
                JSON.stringify({
                  ...storageDetail,
                  desc: formik.values.body,
                })
              );
              localStorage.setItem('previousPath', '/u/new/');
              localStorage.setItem('loginFromCreation', 'true');
              navigate('/login');
            }}
          >
            <FormattedMessage id="label.login" defaultMessage="Login" />
          </LoginBtn>
        )}
      </div>
      <Instructions listOfIntructions={Step3InstructionsList} />
    </>
  );
};

export default Step3;
