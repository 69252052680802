/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import Colors from '../../globals/Colors';
import Button from '../Button';

interface ConfirmPublishModalProps {
  open: { open: boolean; consent: boolean };
  setOpen: any;
}

Modal.setAppElement('#root');

const ConfirmPublishModal = (props: ConfirmPublishModalProps) => {
  const { open, setOpen } = props;

  return (
    <Modal
      isOpen={open.open}
      ariaHideApp={false}
      onRequestClose={() => setOpen(false, false)}
      contentLabel="Action Modal"
      closeTimeoutMS={200}
      style={window.innerWidth < 660 ? customMobileStyles : customStyles}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
    >
      <ModalContentWrapper>
        <Cross onClick={() => setOpen(false, false)}>X</Cross>
        <ModalTitle>
          <FormattedMessage
            id="label.confirmPublish"
            defaultMessage="Confirm Publish"
          />
        </ModalTitle>
        <ModalContent>
          <FormattedMessage
            id="label.publishFinalMsg"
            defaultMessage="Petition cannot be further edited or updated after getting published.
          Are you sure you want to proceed publishing the petition?"
          />
        </ModalContent>
        <ModalActionButtons>
          <Cancel onClick={() => setOpen(false, false)}>
            <FormattedMessage id="label.cancel" defaultMessage="Cancel" />
          </Cancel>
          <Confirm id="publishModalButton" onClick={() => setOpen(false, true)}>
            <FormattedMessage id="label.publish" defaultMessage="Publish" />
          </Confirm>
        </ModalActionButtons>
      </ModalContentWrapper>
    </Modal>
  );
};

export default ConfirmPublishModal;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50vw',
    height: '30vh',
    maxWidth: '600px',
    maxHeight: '400px',
    minHeight: '300px',
  },
};

const customMobileStyles = {
  content: {
    top: '0%',
    left: '0%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '0%',
    width: '100vw',
  },
};
const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;
const ModalTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin: 20px;
`;

const Cross = styled.div`
  font-size: 20px;
  background-color: ${Colors.redOrange};
  color: white;
  padding: 2px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  border: 0.3px solid ${Colors.lightGray};
  font-weight: normal;
  cursor: pointer;
  position: fixed;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
`;

const ModalContent = styled.div`
  margin: 30px 0;
`;

const ModalActionButtons = styled.div`
  display: flex;
  flex-direction: row;
`;
const Confirm = styled(Button)`
  margin: 0 20px;
  height: 40px;
  padding: 0 20px;
`;
const Cancel = styled(Confirm)`
  background: ${Colors.white};
  color: ${Colors.redOrange};
`;
