import * as React from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import { IntlProvider, FormattedNumber, useIntl } from 'react-intl';
import Thermometer from '../Thermometer';
import ActionForm from '../ActionForm';
import Colors from '../../globals/Colors';
import { mobile, tablet } from '../../globals/Devices';

interface RightContentProps {
  editMode: boolean;
  thermometer: { achieved: number; goal: number };
  petitionId: string;
  id: string;
  direction: string;
}

interface WrapperProps {
  id: string;
}
const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  width: 40%;
  margin: ${ props => props.dir === 'rtl' ? '0 20px 0 0;' : '0 0 0 20px;'}
  @media ${tablet} {
    width: unset;
    max-width: 70vw;
    margin: 0 auto;
  }
  @media ${mobile} {
    max-width: 100vw;
  }
`;

const ThermometerValue = styled.div`
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.17px;
  color: ${Colors.blueWhale};
`;

const RightContent = (props: RightContentProps) => {
  const { thermometer, petitionId, id, direction } = props;

  const { formatMessage: fm, formatNumber: fn } = useIntl();
  ReactGA.pageview('/petition/details');

  return (
    <>
      <IntlProvider locale="en">
        <Wrapper id={id} dir={direction}>
          <ThermometerValue>
            <strong>
              <FormattedNumber value={thermometer?.achieved} />
            </strong>
            <span>
              {fm(
                {
                  id: 'label.thermometer',
                  defaultMessage: ` have signed. Let's get to ${thermometer?.goal}!`,
                },
                { target: fn(thermometer?.goal) }
              )}
            </span>
          </ThermometerValue>
          <div style={{ margin: '25px 0' }}>
            <Thermometer
              width={(thermometer?.achieved / thermometer?.goal) * 100}
              dir={direction}
            />
          </div>
          <ActionForm petitionId={petitionId} />
        </Wrapper>
      </IntlProvider>
    </>
  );
};

export default RightContent;
