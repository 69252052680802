/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:8870db04-d061-43aa-884c-07765b327e23",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_f0GF80X3F",
    "aws_user_pools_web_client_id": "4q1snn1pfbb7ngnedohfdhmsnu",
    "oauth": {
        "domain": "communityaf1e375e-af1e375e-prod.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://development.d2lilqwqx30886.amplifyapp.com/,https://community.sumofus.org/,http://localhost:8000/",
        "redirectSignOut": "https://development.d2lilqwqx30886.amplifyapp.com/,https://community.sumofus.org/,http://localhost:8000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_login_mechanisms": [
        "EMAIL",
        "FACEBOOK"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://pk7xq7g5j5a67f2submklkawsy.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-ybpupbcml5d53ov2y3uiszmd5i",
    "aws_user_files_s3_bucket": "community-petition-images-bucket144907-prod",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "SpamSignatures-prod",
            "region": "eu-west-1"
        }
    ],
    "aws_content_delivery_bucket": "community-20200623115836-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://dobjpd4eoqryc.cloudfront.net"
};


export default awsmobile;
