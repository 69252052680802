/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import captcha from '../../recaptcha';
import FBLogin from './FacebookLogin';
import { SignUpValidationSchema } from './helpers/ValidationSchema';
import { Wrapper, Margin, Divider, Links } from './helpers/AuthStyles';
import Input from '../Input';
import Button from '../Button';

interface SignUpProps {
  changeAuthState: any;
  setBannerDetails: any;
  setAutoFillData: any;
}
interface SignUpFormValues {
  email: string;
  name: string;
  password: string;
}

const SignUp = (props: SignUpProps) => {
  const { changeAuthState, setBannerDetails, setAutoFillData } = props;
  const [loading, setLoading] = useState<boolean>(false);

  const { formatMessage: fm } = useIntl();

  const initialValues: SignUpFormValues = {
    email: '',
    name: '',
    password: '',
  };

  const formik = useFormik({
    initialValues,
    validateOnMount: false,
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: SignUpValidationSchema,
    onSubmit: async (values: SignUpFormValues) => {
      setBannerDetails({ msg: '', type: 'success', show: false });
      setLoading(true);
      const token = await captcha.execute({ action: 'signup' });
      Auth.signUp({
        username: values.email,
        password: values.password,
        attributes: { name: values.name },
        validationData: {
          recaptchaToken: token,
        },
      })
        .then((resp: any) => {
          setLoading(false);
          if (resp) {
            if (!resp.userConfirmed) {
              setAutoFillData({
                email: values.email,
                password: values.password,
              });
              setBannerDetails({
                msg: fm(
                  {
                    id: 'msg.verificationCodeSent',
                    defaultMessage: `A verification code has been sent to ${values.email}, please enter that code to verify your account`,
                  },
                  { email: values.email }
                ),
                type: 'success',
                show: true,
              });
              changeAuthState('confirm-account');
            }
          }
        })
        .catch((err: any) => {
          setLoading(false);
          if (err) {
            if (err.code === 'UsernameExistsException') {
              setBannerDetails({
                msg: fm(
                  {
                    id: 'msg.userExists',
                    defaultMessage: `User with email address ${values.email} exists, try signing in instead`,
                  },
                  { email: values.email }
                ),
                type: 'error',
                show: true,
              });
              changeAuthState('sign-in');
            }
          }
        });
    },
  });

  return (
    <Wrapper>
      <div className="title">
        <FormattedMessage
          id="label.createAcc"
          defaultMessage="Create your own account"
        />
      </div>
      <FBLogin
        label={fm({
          id: 'label.signUpFB',
          defaultMessage: `Sign up with Facebook`,
        })}
      />
      <Divider>
        <FormattedMessage id="label.or" defaultMessage="or" />
      </Divider>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Input
          autoFocus={false}
          required
          id="email"
          name="email"
          type="text"
          placeholder={fm({
            id: 'label.email',
            defaultMessage: 'Email',
          })}
          onChange={formik.handleChange}
          value={formik.values.email}
          error={
            formik.errors.email ? fm({ id: `${formik.errors.email}` }) : ''
          }
        />
        <Margin />
        <Input
          autoFocus={false}
          required
          id="name"
          name="name"
          type="text"
          placeholder={fm({
            id: 'label.name',
            defaultMessage: 'Name',
          })}
          onChange={formik.handleChange}
          value={formik.values.name}
          error={formik.errors.name ? fm({ id: `${formik.errors.name}` }) : ''}
        />
        <Margin />
        <Input
          autoFocus={false}
          required
          id="password"
          name="password"
          type="password"
          placeholder={fm({
            id: 'label.password',
            defaultMessage: 'Password',
          })}
          onChange={formik.handleChange}
          value={formik.values.password}
          error={
            formik.errors.password
              ? fm({ id: `${formik.errors.password}` })
              : ''
          }
        />
        <Margin />
        <Button
          type="submit"
          disabled={loading}
          onClick={() => {
            formik.validateForm().then((errors: {}) => {
              console.log(Object.keys(errors));
            });
          }}
        >
          {loading
            ? fm({
                id: 'msg.signingUp',
                defaultMessage: 'Signing up...',
              })
            : fm({
                id: 'label.signUp',
                defaultMessage: 'Sign up',
              })}
        </Button>
      </form>
      <Links>
        <div onClick={() => changeAuthState('sign-in')}>
          <FormattedMessage
            id="label.backToSignIn"
            defaultMessage="Back to Sign In"
          />
        </div>
      </Links>
    </Wrapper>
  );
};

export default SignUp;
