import styled from 'styled-components';
import Colors from 'globals/Colors';
import Button from 'components/Button';
import { mobile } from 'globals/Devices';

export const Wrapper = styled.div`
  margin: 30px 20px;
  margin-top: 120px;
  min-height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media ${mobile} {
    min-height: 600px;
  }
`;

export const BGWrapper = styled.div`
  width: 600px;
  background-color: ${Colors.whiteSmoke};
  padding: 40px;
  flex-direction: row;
  @media ${mobile} {
    width: 360px;
    padding: 20px;
  }
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 40px;
  @media ${mobile} {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  align-items: center;
`;

export const Number = styled.div`
  border: 1px solid red;
  border-radius: 50%;
  color: red;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  min-width: 40px;
`;

export const Container = styled.div`
  flex-direction: row;
`;

export const Header = styled.div`
  font-weight: bold;
`;

export const Desc = styled.div``;

export const CTA = styled(Button)`
  max-width: 100%;
  margin-top: 40px;
`;
