// @ts-nocheck
import React from 'react';
import Moment from 'react-moment';

const buildPetitionUrl = (row) => {
  const link = `${window.location.origin}/petition/${row.id}`;
  console.log(row);
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="petitionLink"
    >
      {row.title}
    </a>
  );
};

const shareRatioCalculator = (row) => {
  if (row.actionCounter && row.shareCounter) {
    const shareRatio = (row.actionCounter / row.shareCounter).toFixed(2);
    return shareRatio;
  }
  return 0;
};

const createdAtBuilder = (row) => {
  if (row.createdAt) {
    return <Moment format="DD MMM YYYY">{row.createdAt}</Moment>;
  }
  return null;
};

const petitionUrlAccessor = (row) => buildPetitionUrl(row);
const shareRatioAccessor = (row) => shareRatioCalculator(row);
const createdAtAccessor = (row) => createdAtBuilder(row);

export const tableColumns = [
  {
    Header: 'Share Ratio',
    show: false,
    columns: [
      {
        Header: 'Created At',
        accessor: createdAtAccessor,
        disableSortBy: true,
      },
      {
        Header: 'URL',
        accessor: petitionUrlAccessor,
      },
      {
        Header: 'Actions',
        accessor: 'actionCounter',
      },
      {
        Header: 'Shares',
        accessor: 'shareCounter',
      },
      {
        Header: 'Signs per Share',
        accessor: shareRatioAccessor,
      },
    ],
  },
];

export const tableData = [];
