import React from 'react';
import styled from 'styled-components';
import { mobile } from '../../globals/Devices';
import { SOUBaseUrl } from '../Constants';
import { FormattedMessage } from 'react-intl';

const NavWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 0.5;
  div {
    display: flex;
    justify-content: center;
  }
  @media ${mobile} {
    div {
      margin-top: 15px;
    }
  }
`;

const NavItem = styled.div`
  font-size: 14px;
`;

const Copyright = styled.div`
  position: relative;
  top: 35px;
  font-size: 14px;
  text-align: center;
  @media ${mobile} {
    width: 100vw;
    top: 0;
  }
`;

const NavItems = () => {
  const year = new Date().getFullYear();
  const langSelected: string = localStorage.getItem('locale') || 'en';

  const buildUrl = (path:string) => {
    if (langSelected === 'en') {
      return `${SOUBaseUrl}/${path}`;
    } else {
      return `${SOUBaseUrl}/${langSelected}/${path}`;
    }
  };


  return (
    <NavWrapper>
      <NavItem>
        <a
          href={buildUrl('')}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="label.home" defaultMessage="Home" />
        </a>
        <a
          href={buildUrl('about')}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="label.about" defaultMessage="About" />
        </a>
        <a
          href={buildUrl('privacy')}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="label.privacy" defaultMessage="Privacy" />
        </a>
        <a
          href={buildUrl('contact')}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="label.contact" defaultMessage="Contact" />
        </a>
      </NavItem>
      <Copyright>
        {`© ${year} SumOfUs, Creative Commons Attribution 3.0 Unported License.`}
      </Copyright>
    </NavWrapper>
  );
};

export default NavItems;
