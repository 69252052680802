import * as React from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { useIntl, FormattedMessage } from 'react-intl';

import PetitionTitle from './PetitionTitle';
import PetitionContent from './PetitionContent';
import SupporterBand from './SupporterBand';
import PetitionImage from './PetitionImage';
import Colors from '../../globals/Colors';
import { tablet, mobile } from '../../globals/Devices';
import Button from '../Button';
import { createPetitionDownload, closePetition } from '../../graphql/mutations';
import { gqlOp } from '../../services/graphql';
import {
  CreatePetitionDownloadMutation,
  CreatePetitionDownloadMutationVariables,
  ClosePetitionMutation,
  ClosePetitionMutationVariables,
} from '../../API';
import ConfirmCloseModal from './ConfirmCloseModal';

type Props = {
  id: string;
  title: string;
  // target: string;
  body: string;
  imageUrl: string;
  actionCounter: number | null;
  publishedAt: string | null;
  createdAt: string | null;
  moderationStatus: string | null;
};

const RootWrapper = styled.div`
  border: solid 1px ${Colors.eastBay3};
  border-radius: 4px;
  width: 770px;
  flex: 1;
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  @media ${tablet} {
    width: 80vw;
  }
`;

const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ContentWrapper = styled.div`
  flex: 0.63;
  padding: 25px 20px;
  cursor: pointer;
  @media ${tablet} {
    flex: 1;
  }
`;

const ImageWrapper = styled.div`
  flex: 0.37;
  padding: 25px 20px;
  @media ${tablet} {
    display: none;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media ${mobile} {
    flex-direction: column;
    align-items: center;
  }
`;

const DownloadButton = styled(Button)`
  margin: 10px;
  width: auto;
  min-width: 300px;
  max-width: 450px;
  background: ${Colors.white};
  color: ${Colors.redOrange};
  &:disabled {
    font-size: 14px;
  }
`;

const CloseButton = styled(Button)`
  margin: 10px;
  background: ${Colors.white};
  color: ${Colors.redOrange};
`;

export default function PetitionList(props: Props) {
  const {
    id,
    title,
    body,
    imageUrl,
    publishedAt,
    actionCounter,
    createdAt,
    moderationStatus,
  } = props;

  const { formatMessage: fm } = useIntl();

  const [buttonText, setButtonText] = React.useState<string>(
    actionCounter
      ? fm({
          id: 'label.downloadSignContent1',
          defaultMessage: 'Download Signatures',
        })
      : fm({
          id: 'label.downloadSignContent2',
          defaultMessage: 'No signatures recorded',
        })
  );

  const [loading, setLoading] = React.useState<boolean>(false);

  const petitionCloseAcceptedStatuses = ['UNMODERATED', 'PENDING', 'APPROVED'];
  const [confirmModal, setConfirmModal] = React.useState<{
    open: boolean;
    consent: boolean;
  }>({ open: false, consent: false });

  let localeDir: string = localStorage.getItem('dir') || 'ltr';

  async function downloadSignatures(values: any) {
    const resp = await gqlOp<
      CreatePetitionDownloadMutation,
      CreatePetitionDownloadMutationVariables
    >(createPetitionDownload, 'AMAZON_COGNITO_USER_POOLS', {
      input: values,
    });

    return resp;
  }

  function downloadPetitionSign(values: any) {
    downloadSignatures(values)
      .then((resp: any) => {
        setButtonText(
          fm({
            id: 'label.downloadSignContent3',
            defaultMessage: 'Check email for download link',
          })
        );
        setTimeout(() => {
          setButtonText(
            fm({
              id: 'label.downloadSignContent1',
              defaultMessage: 'Download Signatures',
            })
          );
        }, 1000 * 15);
      })
      .catch((err: any) => {
        console.log(err);
        setButtonText(
          fm({
            id: 'label.downloadSignContent4',
            defaultMessage: 'Failed to generate link, please try again later',
          })
        );
        setTimeout(() => {
          setButtonText(
            fm({
              id: 'label.downloadSignContent1',
              defaultMessage: 'Download Signatures',
            })
          );
        }, 1000 * 5);
      });
  }

  async function closePetitionFunc(id: string) {
    const resp = await gqlOp<
      ClosePetitionMutation,
      ClosePetitionMutationVariables
    >(closePetition, 'AMAZON_COGNITO_USER_POOLS', {
      id,
    });

    return resp;
  }

  React.useEffect(() => {
    if (confirmModal.consent) {
      setLoading(true);
      closePetitionFunc(id)
        .then((resp: any) => {
          navigate(0);
          console.log(resp);
        })
        .catch((error: any) => {
          // setSaveError(true);
          console.log(error);
          setLoading(false);
        });
    }
  }, [confirmModal]);

  return (
    <>
      <RootWrapper>
        <FirstRow
          onClick={() => {
            navigate(publishedAt ? `/petition/${id}/` : `/u/${id}/`);
          }}
        >
          <ContentWrapper>
            <PetitionTitle>{title}</PetitionTitle>
            <PetitionContent content={body} />
          </ContentWrapper>

          <ImageWrapper>
            <PetitionImage url={imageUrl} />
            <SupporterBand totalSupporters={actionCounter || 0} direction={localeDir} />
          </ImageWrapper>
        </FirstRow>
        <Buttons>
          <DownloadButton
            onClick={() => {
              setButtonText(
                fm({
                  id: 'label.downloadSignContent5',
                  defaultMessage: 'Generating link...',
                })
              );
              downloadPetitionSign({ petitionId: id, createdAt });
            }}
            disabled={
              buttonText !==
                fm({
                  id: 'label.downloadSignContent1',
                  defaultMessage: 'Download Signatures',
                }) || actionCounter === null
            }
          >
            {buttonText}
          </DownloadButton>
          {moderationStatus &&
            petitionCloseAcceptedStatuses.includes(moderationStatus) && (
              <CloseButton
                onClick={() => setConfirmModal({ open: true, consent: false })}
                disabled={loading}
              >
                <FormattedMessage
                  id="label.confirmClose"
                  defaultMessage="Close Petition"
                />
              </CloseButton>
            )}
          <ConfirmCloseModal
            open={confirmModal}
            setOpen={(openFlag: boolean, consentFlag: boolean) => {
              setConfirmModal({
                open: openFlag,
                consent: consentFlag,
              });
            }}
          />
        </Buttons>
      </RootWrapper>
    </>
  );
}
