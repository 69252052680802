import Colors from 'globals/Colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 30px 20px;
  margin-top: 120px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  .petitionLink {
    color: ${Colors.robinsEggBlue};
    font-weight: 600;
  }
`;

export const Heading = styled.h2`
  text-align: center;
`;

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
