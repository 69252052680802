import React from 'react';
import { RouteComponentProps, navigate } from '@reach/router';

import {
  Wrapper,
  BGWrapper,
  Title,
  Section,
  Number,
  Container,
  Header,
  Desc,
  CTA,
} from './Styles';
import { FormattedMessage, useIntl } from 'react-intl';

const AdDetailsPage = (props: RouteComponentProps) => {
  const { formatMessage: fm } = useIntl();

  const StepItems = [
    {
      number: 1,
      title: fm({
        id: 'label.adLandingContent1Title',
        defaultMessage: 'Create new petition',
      }),
      desc: fm({
        id: 'label.adLandingContent1Desc',
        defaultMessage:
          'Write it down. Give it your best shot and that is what matters.',
      }),
    },
    {
      number: 2,
      title: fm({
        id: 'label.adLandingContent2Title',
        defaultMessage: 'Build support',
      }),
      desc: fm({
        id: 'label.adLandingContent2Desc',
        defaultMessage: 'SumOfUs can help you build support for your cause.',
      }),
    },
    {
      number: 3,
      title: fm({
        id: 'label.adLandingContent3Title',
        defaultMessage: 'Change the world',
      }),
      desc: fm({
        id: 'label.adLandingContent3Desc',
        defaultMessage:
          'Work with the decision maker to reach an outcome that solves for the problem ',
      }),
    },
  ];

  return (
    <Wrapper>
      <BGWrapper>
        <Title>
          <FormattedMessage
            id="label.adLandingTitle"
            defaultMessage="Here's how Community SumOfUs Petitions work: "
          />
        </Title>
        {StepItems.map((step, idx) => {
          return (
            <Section>
              <Number>{step.number}</Number>
              <Container>
                <Header>{step.title}</Header>
                <Desc>{step.desc}</Desc>
              </Container>
            </Section>
          );
        })}
        <CTA
          onClick={() => {
            localStorage.removeItem('newPetition');
            navigate('/u/new/');
          }}
        >
          <FormattedMessage
            id="label.adLandingMsg"
            defaultMessage="Got it! I am ready to create my petition"
          />
        </CTA>
      </BGWrapper>
    </Wrapper>
  );
};

export default AdDetailsPage;
