import * as React from 'react';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import { useIntl } from 'react-intl';
import 'react-quill/dist/quill.snow.css';
import Colors from '../../globals/Colors';

export interface Props {
  onChange: any;
  value: string;
  error: string;
  pasteContent?: boolean;
  textPresent?: boolean;
}

const ErrorContainer = styled.div`
  height: 40px;
  width: 414px;
  padding: 0 12px;
  display: flex;
  color: ${Colors.red};
  font-size: 14px;
  align-items: center;
  margin-top: 50px;
`;

const Editor = (props: Props) => {
  let reactQuillRef: any = null;
  const { value, onChange, error } = props;

  const { formatMessage: fm } = useIntl();
  const localeDir = localStorage.getItem('dir') || 'ltr';
  const className = localeDir === 'rtl' ? 'ql-editor-rtl' : 'ql-editor-ltr';

  console.log(reactQuillRef);

  return (
    <>
      <ReactQuill
        id="editor"
        style={{
          height: '100%',
          width: '100%',
          maxHeight: '400px',
          marginBottom: '60px',
        }}
        ref={(el: any) => {
          reactQuillRef = el;
        }}
        value={value}
        modules={Editor.modules}
        formats={Editor.formats}
        placeholder={fm({
          id: 'label.contentPlaceholder',
          defaultMessage:
            'Explain what needs to happen and who can make the change.',
        })}
        onChange={onChange}
        className={className}
      />
      {error && <ErrorContainer>{error}</ErrorContainer>}
    </>
  );
};

Editor.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }],
    ['bold', 'italic'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
  ],
  clipboard: {
    matchVisual: false,
  },
};

Editor.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'image',
  'video',
  'link',
];

export default Editor;
