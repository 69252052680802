import React, { useEffect } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import styled from 'styled-components';
import PetitionTitle from '../PetitionViewComponents/PetitionTitle';
import LeftContent from '../PetitionViewComponents/LeftContent';
import PetitionNotAvailable from '../PetitionViewComponents/PetitionNotAvailable';
import PreviewActionButtons from '../PetitionViewComponents/PreviewActionButtons';
import { getPetition } from '../../graphql/queries';
import {
  GetPublishedPetitionQuery,
  GetPetitionQueryVariables,
} from '../../API';
import { PetitionContext } from '../../services/petition.service';
import { tablet, mobile } from '../../globals/Devices';
import { gqlOp } from '../../services/graphql';

interface PreviewRouteProps extends RouteComponentProps {
  petitionId?: string;
}

const Wrapper = styled.div`
  margin-top: 120px;
  min-height: calc(100vh - 250px);
  @media ${mobile} {
    margin-top: 80px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 60px;
  justify-content: center;
  @media ${tablet} {
    flex-direction: column;
    margin-top: 0px;
  }
`;

const Preview = (props: PreviewRouteProps) => {
  const { petitionId } = props;
  const petitionDetailsFromContext: any = React.useContext(PetitionContext);
  const { petitionDetails, setPetitionDetails } = petitionDetailsFromContext;
  const [loading, setLoading] = React.useState<boolean>(true);
  const [isEditMode, setEditMode] = React.useState<boolean>(false);
  let localeDir: string = localStorage.getItem('dir') || 'ltr';

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const elem = document.getElementById('right-content');
      if (elem) {
        if (window.innerWidth < 800) {
          elem.style.display = 'none';
        } else {
          elem.style.display = 'flex';
        }
      }
    });
  }, []);

  React.useEffect(() => {
    const fetchPetition = async () => {
      if (petitionId) {
        setPetitionDetails(null);
        try {
          const resp = await gqlOp<
            GetPublishedPetitionQuery,
            GetPetitionQueryVariables
          >(getPetition, 'AMAZON_COGNITO_USER_POOLS', {
            id: petitionId,
          });
          localStorage.setItem(
            'petitionDetails',
            JSON.stringify(resp.getPetition)
          );
          console.log(resp.getPetition);
          setPetitionDetails(resp.getPetition);
          setLoading(false);
        } catch (error) {
          navigate(`/petition/${petitionId}`);
          setPetitionDetails(null);
          setLoading(false);
          console.log('petition details error', error);
        }
      } else {
        console.log('usercontext');
      }
    };
    fetchPetition();
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '26px',
            height: '90vh',
          }}
        >
          Loading...
        </div>
      ) : (
        <>
          {petitionDetails && (
            <>
              <Wrapper>
                <PetitionTitle
                  petitionTitle={petitionDetails.title}
                  editMode={isEditMode}
                />
                <PreviewActionButtons
                  editMode={isEditMode}
                  switchEditMode={(flag: boolean) => setEditMode(flag)}
                />
                <ContentWrapper>
                  <LeftContent
                    petitionId={petitionId || null}
                    imageUrl={petitionDetails.imageUrl}
                    content={petitionDetails.body}
                    owner={petitionDetails.user.name}
                    target={petitionDetails.target}
                    editMode={isEditMode}
                    direction={localeDir}
                  />
                </ContentWrapper>
              </Wrapper>
            </>
          )}
          {petitionDetails === null && <PetitionNotAvailable />}
        </>
      )}
    </>
  );
};

export default Preview;
