/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const WarningContainer = styled.div`
  margin: 0;
  padding: 10px;
  color: red;
  border: 2px solid red;
  clear: both;
  font-size: 14px;
`
const WarningTitle = styled.h5`
  font-weight: bold;
  margin-bottom: 10px;
`;

const WarningMessage = styled.p`
  margin: 0;
  padding: 0;
`;

const OptOutWarning = () => {
  return (
    <WarningContainer>
      <WarningTitle>
        <FormattedMessage id="label.consent.opt_out_warning.title"  />
      </WarningTitle>
      <WarningMessage>
        <FormattedMessage id="label.consent.opt_out_warning.message"  defaultMessage="If you choose ‘Yes,’ we’ll keep you posted about this petition and others like it that need your help to win."/>
      </WarningMessage>
    </WarningContainer>
  )
};

export default OptOutWarning;
