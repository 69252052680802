import React, { useState, useContext, useEffect } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { useFormik } from 'formik';
import ReactGA from 'react-ga';
import * as Yup from 'yup';
import styled from 'styled-components';
import Pulse from 'react-reveal/Pulse';
import queryString from 'querystring';
import { useIntl } from 'react-intl';
import Step1 from '../../components/NewPetition/Step1';
import Step2 from '../../components/NewPetition/Step2';
import Step3 from '../../components/NewPetition/Step3';
import Step4 from '../../components/NewPetition/Step4';
import ConsentPopup from '../../components/NewPetition/ConsentPopup';
import Stepper from '../../components/Stepper';
import { createPetition } from '../../graphql/mutations';
import { mobile } from '../../globals/Devices';
import { AuthContext } from '../../services/auth.service';
import { gqlOp } from '../../services/graphql';
import {
  CreatePetitionMutation,
  CreatePetitionMutationVariables,
} from '../../API';
import Banner from '../../components/Banner';
import { LangContext } from 'services/lang.service';

interface FormValues {
  title: string;
  target: string;
  body: string;
  imageUrl: string;
}
const StepperWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 130px;
  margin: 50px 0;
  @media ${mobile} {
    justify-content: space-evenly;
  }
`;

const Step1Schema = Yup.object().shape({
  title: Yup.string()
    .required('label.titleRequired')
    .test(
      'emptySpace',
      'label.titleBlank',
      (value) => value && value.replace(/ /g, '').length
    ),
});

const Step2Schema = Yup.object().shape({
  target: Yup.string()
    .required('label.targetRequired')
    .test(
      'emptySpace',
      'label.targetBlank',
      (value) => value && value.replace(/ /g, '').length
    ),
});

const Step3Schema = Yup.object().shape({
  body: Yup.string().required('label.contentRequired'),
});

const Step4Schema = Yup.object().shape({
  imageUrl: Yup.mixed().required('label.fileRequired'),
});

const schemaArray = [Step1Schema, Step2Schema, Step3Schema, Step4Schema];

const NewPetition = (props: RouteComponentProps) => {
  const authContext = useContext(AuthContext);

  const langContext: any = useContext(LangContext);
  const { lang } = langContext;

  const event = new Event('gtag_conversion'); // To trigger conversion event to google ad tag manager

  const storageData = localStorage.getItem('newPetition');
  const storageDetail = storageData ? JSON.parse(storageData) : {};
  const [step, updateStep] = useState(0);
  const [modal, setModal] = useState<{ open: boolean; error: boolean }>({
    open: false,
    error: false,
  });

  const { formatMessage: fm } = useIntl();

  useEffect(() => {
    function redirectStep() {
      const redirectLogin = localStorage.getItem('loginFromCreation');
      if (redirectLogin && redirectLogin === 'true') {
        localStorage.removeItem('loginFromCreation');
        const stepNumber = Object.keys(storageDetail).length || 0;
        updateStep(stepNumber > 2 ? 2 : stepNumber);
      }
    }

    redirectStep();
  }, [storageDetail]);

  function getInitialValues() {
    if (Object.keys(storageDetail).length) {
      const initialValues: FormValues = {
        title: storageDetail.title || '',
        target: storageDetail.target || '',
        body: storageDetail.desc || '',
        imageUrl: '',
      };
      return initialValues;
    }
    const initialValues: FormValues = {
      title: '',
      target: '',
      body: '',
      imageUrl: '',
    };
    return initialValues;
  }

  const formik = useFormik({
    initialValues: getInitialValues(),
    validateOnMount: false,
    validationSchema: schemaArray[step],
    onSubmit: (values: any) => {
      console.log(JSON.stringify(values));
    },
  });

  const nextStep = () => {
    async function savePetitionData(values: any) {
      // @ts-ignore
      const { name, email } = authContext.attributes;
      const data = {
        ...values,
        user: {
          name,
          email,
        },
        queryParams: JSON.stringify(
          queryString.parse(
            window.sessionStorage.getItem('akQueryString') || ''
          )
        ),
        lang: lang ? lang : 'en',
      };
      const resp = await gqlOp<
        CreatePetitionMutation,
        CreatePetitionMutationVariables
      >(createPetition, 'AMAZON_COGNITO_USER_POOLS', {
        input: data,
      });

      return resp;
    }

    formik.validateForm().then((errors: {}) => {
      if (Object.keys(errors).length === 0) {
        if (step === schemaArray.length - 1) {
          console.log(JSON.stringify(formik.values));
          setModal({
            open: false,
            error: false,
          });
          const doCreatePetition = (values: FormValues) => {
            savePetitionData(values)
              .then((r: any) => {
                // setPetitionInfo(r.data.getPetition);
                localStorage.removeItem('newPetition');
                ReactGA.event({
                  category: 'Petition Creation',
                  action: `Completed step 4`,
                });
                ReactGA.pageview('/petition/completed/step4');
                console.log(r);
                document.dispatchEvent(event);
                navigate(`/u/${r.createPetition.id}`);
              })
              .catch((error: any) => {
                setModal({
                  open: false,
                  error: true,
                });
                setTimeout(() => {
                  setModal({
                    open: false,
                    error: false,
                  });
                }, 5000);
                ReactGA.event({
                  category: 'Error',
                  action: 'Error displayed',
                  label: `Create petition error`,
                });
                console.log('error', error);
              });
          };

          doCreatePetition(formik.values);
        } else {
          switch (step) {
            case 0:
              ReactGA.event({
                category: 'Petition Creation',
                action: `Completed step 1`,
              });
              ReactGA.pageview('/petition/completed/step1');
              localStorage.setItem(
                'newPetition',
                JSON.stringify({
                  ...storageDetail,
                  title: formik.values.title,
                })
              );
              break;
            case 1:
              ReactGA.event({
                category: 'Petition Creation',
                action: `Completed step 2`,
              });
              ReactGA.pageview('/petition/completed/step2');
              localStorage.setItem(
                'newPetition',
                JSON.stringify({
                  ...storageDetail,
                  target: formik.values.target,
                })
              );
              break;
            case 2:
              ReactGA.event({
                category: 'Petition Creation',
                action: `Completed step 3`,
              });
              ReactGA.pageview('/petition/completed/step3');
              localStorage.setItem(
                'newPetition',
                JSON.stringify({
                  ...storageDetail,
                  desc: formik.values.body,
                })
              );
              break;
            default:
              break;
          }
          updateStep(step + 1);
        }
      }
    });
  };

  return (
    <div style={{ marginTop: '120px' }}>
      <Pulse>
        <StepperWrapper>
          <Stepper value={1} current={step + 1} onClick={() => updateStep(0)} />
          <Stepper value={2} current={step + 1} onClick={() => updateStep(1)} />
          <Stepper value={3} current={step + 1} onClick={() => updateStep(2)} />
          <Stepper value={4} current={step + 1} onClick={() => updateStep(3)} />
        </StepperWrapper>
      </Pulse>
      <div>
        <form onSubmit={formik.handleSubmit}>
          <Step1 formik={formik} handleNextStep={nextStep} currentStep={step} />
          <Step2 formik={formik} handleNextStep={nextStep} currentStep={step} />
          <Step3 formik={formik} handleNextStep={nextStep} currentStep={step} />
          <Step4
            formik={formik}
            handleNextStep={nextStep}
            currentStep={step}
            openModal={setModal}
          />
        </form>
        <ConsentPopup
          openModal={modal.open}
          setModalOpen={setModal}
          setClick={nextStep}
        />
        {modal.error && (
          <Banner
            msg={fm({
              id: 'label.saveUnable',
              defaultMessage:
                'Unable to save the petition at the moment, please try again later',
            })}
            setShowBanner={(flag: boolean) => {
              setModal({ error: flag, open: false });
            }}
            type="error"
          />
        )}
      </div>
    </div>
  );
};

export default NewPetition;
